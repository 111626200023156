import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";

const NewsPage = () => (
  <Layout>
    <Seo title="News—December 2010" />
	<NewsHeader />
    <h2>December 2010</h2>



	<h3 className="blogdate">
	Sunday, December 19, 2010
	</h3>

	<h4 className="blogitemtitle">
	All I want for Christmas is the Rec Devs
	</h4>
	   <div className="blogitembody">
	   <p>
	Just in time for Christmas, it's <i>Jesse Rivest and the Recent
	Developments</i> dropping down the chimney of Mighty Mighty (Wellington)
	this Wednesday eve! We've got a tasty plate of tunes on the table, short
	and sweet like Grandma's shortbread - hope you can make it. Bring your
	own milk.
	</p>
	<p>
	Once again, the Recent Developments are:
	</p>
	<ul>
	<li>
	Brendan Schenk on banjo/accordion/mandolin
	</li>
	<li>
	Peter Cogswell on wire brushes, kick-box, shakers, tambourines and
	yes, even sleigh bells
	</li>
	</ul>
	<p>
	With some travelling and adventures in store for a couple of us in the
	new year, it's uncertain as to when our next appearance will be - so we
	hope you can make this show! And honestly, where would you rather be -
	late last minute shopping or hanging at the ever cool Mighty Mighty with
	us?
	</p>
	<ul>
	<li>
	music starts <b>8:00pm</b> on December 22 @ <b>Mighty Mighty</b> on
	Cuba Street, Wellington
	</li>
	<li>
	The Wanted Sessions evening - we're in between the lovely sounding <i>City
	Oh Sigh</i> and the newly expanded <i>Blackwater</i>
	</li>
	</ul>
	<p>
	Happy holidays!
	</p>
	<p>
	Jesse
	</p>
	   </div>
	   	   <div className="blogitemfooter">
	   <p>posted by Jesse @ 17:54 +1300</p>
	   </div>





	<p>
		<Link to="/news/">Go back to the news index page</Link>
	</p>
  </Layout>
);

export default NewsPage;
